import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IMealDay } from '../interfaces/meal-day.interface';

@Component({
    selector: 'app-meal-day',
    templateUrl: './meal-day.component.html',
    styleUrls: ['./meal-day.component.css']
})
export class MealDayComponent {
    @Input() mealDay: IMealDay;
    @Input() mealIndex: number;
    @Output() removeDay = new EventEmitter();

    addMeal() {
        this.mealDay.meals.push({
            name: '',
            items: [],
        });
    }

    removeMeal(index: number) {
        this.mealDay.meals.splice(index, 1);
    }

    isAddMealDisabled() {
        return false;
    }

    removeSingleDay() {
        this.removeDay.emit();
    }

    nutritionInfo() {
        let calories = 0;
        let carbs = 0;
        let fat = 0;
        let protein = 0;

        this.mealDay.meals.forEach((meal) => {
            meal.items.forEach((item) => {
                calories += item.calories;
                carbs += item.carbs;
                fat += item.fat;
                protein += item.protein;
            });
        });

        return `${calories} Kcal (C ${carbs}g, F ${fat}g, P ${protein}g)`;
    }
}
