import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ISingleMeal } from '../interfaces/single-meal.interface';

@Component({
    selector: 'app-single-meal',
    templateUrl: './single-meal.component.html',
    styleUrls: ['./single-meal.component.css']
})
export class SingleMealComponent {
    @Input() meal: ISingleMeal;
    @Output() removeMeal = new EventEmitter();

    addItem() {
        this.meal.items.push({
            type: 'order',
            calories: 0,
            carbs: 0,
            fat: 0,
            protein: 0,
        });
    }

    removeItem(index: number) {
        this.meal.items.splice(index, 1);
    }

    removeSingleMeal() {
        this.removeMeal.emit();
    }

    nutritionInfo() {
        let calories = 0;
        let carbs = 0;
        let fat = 0;
        let protein = 0;

        this.meal.items.forEach((item) => {
            calories += item.calories;
            carbs += item.carbs;
            fat += item.fat;
            protein += item.protein;
        });

        return `${calories} Kcal (C ${carbs}g, F ${fat}g, P ${protein}g)`;
    }
}
